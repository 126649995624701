@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;
@use 'src/components/TopMenu/TopMenu' as TopMenu;

.bento-menu__container {

  .bento,
  .bento.p-button.p-button-icon-only:not(.p-button-sm) {
    width: TopMenu.$header-item-size;
    height: TopMenu.$header-item-size;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;

    span:first-child {
      padding: 0;
    }

    span:first-child::before {
      width: TopMenu.$header-item-size;
      height: TopMenu.$header-item-size;
      color: Colors.$text-primary;
    }

    &:focus, &:focus:hover {
      border: 1px solid Colors.$text-primary;
      border-radius: Tools.asRem(4px);

      .p-button-icon:before {
        color: Colors.$text-primary;
      }
    }

    &:hover .p-button-icon:before {
      color: Colors.$ob-brand-orange;
    }
  }

  .bento-menu__overlay {
    padding: 0;
    margin: 0;
    width: Tools.asRem(478px);

    &:before,
    &:after {
      display: none;
    }

    .p-overlaypanel-content,
    .bento-menu__slide {
      width: 100%;
      flex-grow: 1;
      padding: 0;
      gap: 0;

      header {
        flex-grow: 1;
        padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
        flex-direction: row;
        justify-content: space-between;
        background-color: Colors.$datatable-header-background;

        .default-module-container {
          flex-direction: row;
          align-items: center;
          gap: Tools.asRem(Spacing.$gutter-small);
          font-size: Tools.asRem(13px);
          color: Colors.$text-primary;
          font-family: OpenSans, sans-serif;

          .p-dropdown {
            min-height: TopMenu.$header-item-size;
          }
        }

        .p-dropdown-panel {
          // Overwrite default styles to align to right side of control (panelStyle prop doesn't work)
          right: 0 !important;
          left: auto !important;
          width: auto !important;
        }
      }

      .bento-menu__items {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: left;
        gap: Tools.asRem(Spacing.$gutter-micro);
        padding: Tools.asRem(Spacing.$gutter-small);
        margin: 0;
        background-color: Colors.$panel-background;

        button {
          width: Tools.asRem(112px);
          height: Tools.asRem(64px);
          display: flex;
          flex-direction: column;
          gap: Tools.asRem(Spacing.$gutter-micro);
          font-family: Panton, OpenSans, sans-serif;
          font-size: Tools.asRem(14px);
          line-height: Tools.asRem(16px);
          font-weight: 400;
          padding: 0;
          margin: 0;
          align-items: center;
          justify-content: center;
          border: 1px solid transparent;
          border-radius: Tools.asRem(4px);
          color: Colors.$text-primary;
          background-color: transparent;

          &.active {
            border: 1px solid Colors.$ob-brand-orange;
            color: Colors.$ob-brand-orange;
            background-color: Colors.$ob-brand-black;
          }

          &:hover,
          &:hover .p-button-icon::before {
            border: 1px solid transparent;
            color: Colors.$ob-brand-orange;
          }

          &:focus {
            border: 1px solid Colors.$text-primary;
            color: Colors.$text-primary;

            .p-button-icon::before {
              color: Colors.$text-primary;
            }
          }

          .p-button-icon::before {
            width: Tools.asRem(28px);
            height: Tools.asRem(28px);
          }

          .p-badge {
            display: flex;
            position: absolute;
            top: 0;
            right: Tools.asRem(30px);
            align-items: center;
            justify-content: center;
            height: Tools.asRem(20px);
            width: Tools.asRem(20px);
            min-width: Tools.asRem(20px);
            font-family: OpenSans, sans-serif;
            font-size: Tools.asRem(12px);
            line-height: Tools.asRem(12px);
            background-color: Colors.$ob-brand-orange;

            &:before {
              width: Tools.asRem(12px);
              height: Tools.asRem(12px);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    .bento,
    .bento.p-button.p-button-icon-only:not(.p-button-sm) {
      &:hover .p-button-icon:before {
        color: Colors.$text-primary;
      }
    }

    .bento-menu__overlay {
      // Overwrite inline styles from overlayPanel
      position: absolute !important;
      left: 0 !important;
      top: Tools.asRem(48px) !important;
      right: unset;
      bottom: unset;
      width: 100vw;
      height: calc(100vh - 48px);
      border: 0;

      .p-overlaypanel-content,
      .bento-menu__slide {
        padding: 0;
        gap: 0;

        .bento-menu-close {
          height: Tools.asRem(50px);
        }

        header {
          flex-grow: 0;
          border: 1px solid Colors.$panel-border-color;
          border-radius: Tools.asRem(4px) Tools.asRem(4px) 0 0;
        }

        .bento-menu__items {
          border: 1px solid Colors.$panel-border-color;
          border-top: 0;
          border-radius: 0 0 Tools.asRem(4px) Tools.asRem(4px);
          gap: Tools.asRem(3px);

          button:hover,
          button:hover .p-button-icon::before {
            border: 1px solid transparent;
            color: Colors.$text-primary;
          }
        }
      }

      &.slide-enter {
        transform: translateX(-100%);
      }
      &.slide-enter-active {
        transform: translateX(0);
        transition: transform 0.3s ease-out, opacity 0.3s ease-out;
      }
      &.slide-exit {
        transform: translateX(0);
      }
      &.slide-exit-active {
        transform: translateX(-100%);
        transition: transform 0.3s ease-in, opacity 0.3s ease-in;
      }

    }
  }
}