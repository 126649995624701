@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;

.cargo-tracker__header,
.module__canvas:has(> header) > header.cargo-tracker__header {
  padding: Tools.asRem(Spacing.$gutter-large);
  gap: Tools.asRem(Spacing.$gutter-small);
  align-items: flex-start;

  .search-container {
    flex-grow: 1;

    button.expand,
    button.expand:hover,
    .expand.p-button.p-button-text:enabled:hover {
      border: 2px solid transparent;
      padding: Tools.asRem(2px) Tools.asRem(Spacing.$gutter-small);
    }

    * {
      transition: none;
    }

    .token-template--external:before {
      content: '\e94c';
      padding-top: Tools.asRem(5px);
      padding-right: Tools.asRem(Spacing.$gutter-micro);
      font-family: primeicons, sans-serif;
      font-size: Tools.asRem(10px);
      font-weight: bold;
      line-height: 1;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
    }
  }

  .cargo-search__additional {
    @include Tools.dotted-border($edge: left);
    padding-left: Tools.asRem(Spacing.$gutter-medium);
    gap: Tools.asRem(Spacing.$gutter-small);
  }

  .cargo-search-buttons--container {
    display: flex;
    gap: Tools.asRem(Spacing.$gutter-small);
  }

  .autocomplete-container.search-ongoing .p-inputtext:before {
    content: '\e926';
    animation: fa-spin 2s linear infinite;
    top: Tools.asRem(10px);
  }

  & > .p-button {
    line-height: Tools.asRem(18px);
    padding: Tools.asRem(Spacing.$gutter-small)
      Tools.asRem(Spacing.$gutter-medium);
    border-width: Tools.asRem(1px);
    min-width: Tools.asRem(67px);
  }

  .form-input__container {
    max-height: Tools.asRem(70px);
    width: Tools.asRem(180px);
  }

  & > input {
    width: Tools.asRem(160px);
    flex-grow: unset;
  }

  .autocomplete-entity-with-sections {
    .p-autocomplete-items-wrapper {
      .p-autocomplete-items {
        .p-autocomplete-item-group {
          &:has(div.grouped-item-template--charterer),
          &:has(div.grouped-item-template--cargo-code) {
            visibility: hidden; // hide charterer and cargo code section names as they have group names on first item
            height: 0;
            min-height: 0;
            padding: 0;
          }
        }

        .item-template {
          display: grid;
          grid-template-columns:
            minmax(min-content, Tools.asRem(160px)) auto minmax(
              min-content,
              Tools.asRem(80px)
            )
            minmax(min-content, Tools.asRem(21px));

          & img {
            grid-area: unset;
          }

          @media (max-width: 960px) {
            grid-template-columns: auto minmax(min-content, Tools.asRem(80px)) minmax(
                min-content,
                Tools.asRem(21px)
              );
            grid-row-gap: Tools.asRem(Spacing.$gutter-micro);
          }
        }

        .item-template--charterer .item-template-grouplabel,
        .item-template--cargo-code .item-template-grouplabel,
        .grouped-item-template {
          font-family: OpenSans, sans-serif;
          font-size: Tools.asRem(13px);
          font-weight: 700;
          line-height: Tools.asRem(18px);
        }
      }
    }
  }

  .cargo-search-additional-filters {
    .iconoir-filter {
      height: Tools.asRem(24px);
    }

    .p-panel {
      width: auto;

      &-content {
        min-width: Tools.asRem(346px);
        padding: Tools.asRem(Spacing.$gutter-small);
        gap: Tools.asRem(Spacing.$gutter-medium);

        .additional-filters__container--internal {
          display: grid;
          grid-template-columns: 0.8fr minmax(121px, 1fr) minmax(105px, 1fr);

          .p-autocomplete {
            min-width: Tools.asRem(243px);
          }
        }

        @media (max-width: 960px) {
          .additional-filters__container--internal {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            grid-template-columns: none;
          }
        }
      }
      .selectable-filter {
        &__container {
          .p-selectbutton {
            padding-left: 0;

            .p-button {
              min-width: Tools.asRem(81px);
            }
          }
        }
      }
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;
    padding: 0;
    border-top: 1px solid Colors.$full-black;
    border-bottom: 1px solid Colors.$full-black;

    .cargo-tracker-search-mobile__button {
      background: Colors.$charcoal;
      height: Tools.asRem(34px);
      outline: none;
      border-radius: 0;
      width: 100%;
      padding: Tools.asRem(Spacing.$gutter-micro)
        Tools.asRem(Spacing.$gutter-small);
      font-size: Tools.asRem(16px);
      font-style: normal;
      font-weight: 400;
      line-height: Tools.asRem(16px);
      text-align: left;
      text-transform: none;
      color: Colors.$text-secondary;

      .p-button-label {
        font-size: Tools.asRem(16px);
        color: Colors.$text-primary;
      }
      .p-button-icon {
        padding-left: Tools.asRem(Spacing.$gutter-micro);
      }
    }

    .cargo-tracker-search-mobile {
      &__accordion {
        flex-direction: column;
        flex: 1 1 auto;
        padding: 0 !important; // override global (_accordion.scss)
        width: 100%;

        > div {
          flex-direction: column;
        }
      }

      &__accordion-header {
        .p-accordion-header-link {
          border: 0;

          &:focus {
            outline: none;
          }

          .p-accordion-header-text {
            font-size: Tools.asRem(16px);
            margin: 0 Tools.asRem(Spacing.$gutter-small);
          }

          .p-icon {
            margin: 0 Tools.asRem(Spacing.$gutter-micro);
          }

          .p-accordion-header-text,
          .p-icon {
            color: Colors.$text-primary;
          }
        }

        svg {
          width: Tools.asRem(16px);
          height: Tools.asRem(16px);
        }
      }

      &__accordion-tab {
        flex-direction: column;
        flex: 1 1 auto;
        border: 0;

        .p-accordion-content {
          overflow: visible;
        }
      }

      &__container {
        width: 100%;
        height: auto;
        z-index: 2;
        background: Colors.$charcoal;
        border-top: 1px solid Colors.$full-black;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: stretch;
        padding: Tools.asRem(Spacing.$gutter-small);

        .form-input__container {
          width: auto;
        }

        .search-container {
          flex-grow: initial;

          .p-autocomplete
            .p-autocomplete-multiple-container
            .p-autocomplete-input-token
            input {
          }
        }

        .cargo-search-buttons--container {
          display: flex;
          gap: Tools.asRem(Spacing.$gutter-small);

          & > button {
            flex-grow: 1;
            justify-content: center;
          }

          .additional-filters__container {
            .p-panel {
              position: absolute;
              width: max-content;
              right: unset;
              left: 0;
            }

            .additional-filters__button-toggle,
            .additional-filters__overlay-element {
              display: inline-flex;
            }
          }
        }

        .cargo-search-show-results--button {
          width: auto;
          justify-content: center;
        }

        .p-autocomplete-multiple-container {
          overflow-x: hidden;
        }
      }
    }
  }
}
