@use "src/design-system" as *;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/colors" as Colors;

*[class*="iconoir-"] {

    display: flex;

    &::before {
        width: auto;
        height: auto;
        aspect-ratio: 1/1;
        // color: $text-primary;
    }

    &.icon--mini::before { width: Tools.asRem(8px); height: Tools.asRem(8px); };
    &.icon--tiny::before { width: Tools.asRem(16px); height: Tools.asRem(16px); };
    &.icon--small::before { width: Tools.asRem(20px); height: Tools.asRem(20px); };
    &.icon--medium::before { width: Tools.asRem(24px); height: Tools.asRem(24px); };
    &.icon--large::before { width: Tools.asRem(32px); height: Tools.asRem(32px); };

    &.icon--right {
        order: 1;
    }

    &.icon--green::before { color: Colors.$grass-80; }
    &.icon--red::before { color: Colors.$ob-brand-red; }
    &.icon--primary::before { color: Colors.$text-primary; }
    &.icon--secondary::before { color: Colors.$text-secondary; }
    &.icon--ob-orange::before { color: Colors.$ob-brand-orange; }
    &.icon--black::before { color: Colors.$full-black; }
    &.icon--sky-80::before { color: Colors.$sky-80; }

    &.icon--hide { opacity: 0 ;}

    &.icon--rotated {
        transform: rotate(90deg);
        // border: 1px solid red;
    }

    &.iconoir-search-xmark::before { mask-image: url('../../public/img/icons/search_failed.svg'); }

    &.icon--hover {
        &-ob-orange:hover::before {
            color: Colors.$ob-brand-orange;
        }
    }
}