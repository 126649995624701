@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/colors" as Colors;

.cell {
  gap: Tools.asRem(Spacing.$gutter-micro);
  align-items: center;
  flex-grow: 1;
  height: Tools.asRem(32px);

  &:has(> span:nth-child(2)) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 0;
    height: Tools.asRem(36px);
    width: 100%;

    > span:first-child {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-family: OpenSans, sans-serif;
      font-size: Tools.asRem(13px);
      font-weight: 700;
      line-height: Tools.asRem(16px);
      color: Colors.$text-primary;
    }

    > span:nth-child(2) {
      display: flex;
      font-family: OpenSans, sans-serif;
      font-size: Tools.asRem(11px);
      font-weight: 400;
      line-height: Tools.asRem(15px);
      color: Colors.$text-secondary;
    }
  }

  input {
    border-radius: 0;
  }
}

.indent {
  width: Tools.asRem(8px);
}