@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;
@use 'src/components/TopMenu/TopMenu' as TopMenu;

.ma-container {
  height: 100%;
  flex-direction: row;

  .ma-conversation-list {
    width: Tools.asRem(244px);
    max-width: Tools.asRem(244px);
    border-right: 1px solid Colors.$panel-border-color;
    background-color: Colors.$panel-background;

    & > div {
      flex-grow: 1;
      border: 0;

      .p-datatable tr.p-rowgroup-header {
        border-right: 0;
        border-bottom: 1px solid Colors.$datatable-header-border;
      }

      tr:not(.p-rowgroup-header):not(.p-rowgroup-footer) {
        height: Tools.asRem(36px);
      }
    }
  }

  .ma-content {
    flex-direction: column;

    header {
      flex-direction: row;
      justify-content: space-between;
      padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-large);
      height: Tools.asRem(52px);

      button {
        height: Tools.asRem(36px);

        &.ma-toggle-left-panel {
          width: Tools.asRem(42px);
          height: Tools.asRem(36px);
        }
      }
    }

    main {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      flex-grow: 1;
      gap: 0;

      > section {
        width: 100%;
      }
    }
  }

  button.p-disabled {
    opacity: 0.36;
  }

  @media only screen and (max-width: 960px) {
    .ma-conversation-list {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      max-width: 100vw;
      height: 100%;
      overflow: auto;
      z-index: TopMenu.$menu-zIndex + 1;
      flex-direction: column;

      button.ma-toggle-left-panel {
        flex-grow: 0;
        width: Tools.asRem(42px);
        height: Tools.asRem(36px);
        background-color: transparent;
        margin: Tools.asRem(Spacing.$gutter-large) 0 Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
      }

      .p-contextmenu {
        // Overwrite Primereact inline styles
        z-index: TopMenu.$menu-zIndex + 2 !important;
        left: unset !important;
        right: Tools.asRem(35px) !important; // Button width (so it's not covering button)
      }

      .p-datatable {
        top: -1 * Tools.asRem(Spacing.$gutter-micro); // Fix mobile chrome datatable grouping (gap above sticky .p-rowgroup-header)
      }
    }

    .ma-content {
      header {
        height: Tools.asRem(52px);
        padding: Tools.asRem(Spacing.$gutter-small);

        button {
          height: Tools.asRem(36px);
          font-size: Tools.asRem(16px);
        }
      }

      main {

      }
    }
  }
}

// When top menu unpinned -> In this module it should stick to hidden top menu
.app__canvas.top-menu--unpinned > .AppBody > .app-body__content:has(.ma-container) {
  padding-top: Tools.asRem(Spacing.$menu-hidden-height);
}
