@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/colors' as Colors;

.subscribe-panel {
  position: relative;

  .surveillance-subscribe {
    height: Tools.asRem(34px);
    color: Colors.$text-primary;
  }

  .p-panel {
    position: absolute;
    top: Tools.asRem(34px);
    right: 0;
    width: max-content;
    z-index: 9;
    flex-direction: column;

    &-header {
      background: Colors.$panel-background;
      border: 1px solid Colors.$full-black;
      color: Colors.$text-secondary;
      font-family: Panton, sans-serif;
      font-weight: 400;
      font-size: Tools.asRem(14px);
      padding: Tools.asRem(Spacing.$gutter-small);
    }

    &.info-message .p-panel-content {
      padding-top: Tools.asRem(46px);
    }

    &-content {
      position: relative;
      background: Colors.$charcoal;
      border: 1px solid Colors.$full-black;
      border-top: none;
      padding: Tools.asRem(Spacing.$gutter-medium);
      flex-direction: column;
      gap: Tools.asRem(Spacing.$gutter-small);

      .input-row {
        position: relative;
        flex-direction: row;
        gap: 0;

        .p-badge {
          position: absolute;
          left: Tools.asRem(-4px);
          top: Tools.asRem(-4px);
        }

        & > .p-dropdown {
          width: Tools.asRem(217px);
          border-radius: Tools.asRem(3px) 0 0 Tools.asRem(3px);
        }

        & > input {
          width: Tools.asRem(217px);
          border-radius: 0 Tools.asRem(3px) Tools.asRem(3px) 0;
          border-left: 0;

          &.p-disabled {
            opacity: 0.36;
          }
        }

        & > button {
          margin-left: Tools.asRem(Spacing.$gutter-micro);
          width: Tools.asRem(42px);
        }
      }

      .p-inline-message {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        margin: 0;
        padding: Tools.asRem(Spacing.$gutter-small);
        border: 0;
        border-radius: unset;
        height: Tools.asRem(34px);
        text-align: left;
        display: flex;
        justify-content: flex-start;

        .p-inline-message-text {
          font-family: OpenSans, sans-serif;
          font-size: Tools.asRem(13px);
          font-weight: 700;
          line-height: Tools.asRem(18px);
          text-align: left;
          color: Colors.$text-primary;
        }
      }
    }

    .subscribe-panel__close_button {
      aspect-ratio: 1 / 1;
      position: absolute;
      top: Tools.asRem(0px);
      right: 0;
      background-color: transparent !important;
      padding: Tools.asRem(Spacing.$gutter-micro);

      span:first-child {
        padding: 0;

        &::before {
          width: Tools.asRem(20px);
          height: Tools.asRem(20px);
        }
      }
    }
  }

  .p-button {
    overflow: visible;
  }

  $indicator-badge-size: Tools.asRem(16px);

  .p-badge {
    position: absolute;
    left: Tools.asRem(6px);
    top: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: $indicator-badge-size;
    height: $indicator-badge-size;
    min-width: $indicator-badge-size;
    border-radius: calc($indicator-badge-size / 2);
    z-index: 10;
  }

  @media only screen and (max-width: 960px) {
    .p-panel {
      position: initial;
      width: 100%;

      &-content {
        padding: 0;
        border: none;
      }
    }
  }
}

.subscribe-panel-dropdown-panel .p-disabled {
  opacity: 0.36;
}

// Hack for PrimeReact as on first launch it shows menu partly off the screen to the right
.subscribe-panel-context-menu {
  left: unset !important;
  right: Tools.asRem(29px);
}