@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;


$panel-width: 242px;

.panel {
  background: Colors.$panel-background;
  flex-direction: column;
  border-right: 1px solid Colors.$full-black;
  overflow-y: hidden;
  min-width: Tools.asRem(200px);

  @media only screen and (min-width: 960px) {
    width: Tools.asRem($panel-width);
  }
}

.worksheettable {

  & table {
    height: 100%;
  }

  & tbody {
    overflow-y: auto; // auto scroll if there are more elements instead of virtualScroller
    height: 100%;
    display: block;
  }

  & tr {
		display: grid;
		grid-template-columns: Tools.asRem(18px) 1fr Tools.asRem(42px);
		font-size: Tools.asRem(12px);

    &.active {
      // the same as _datatable.scss (.p-selectable-row)
      outline: 1px solid Colors.$ob-brand-orange;
      outline-offset: -1px;
    }
	}
}

.historyTable {
  & tbody tr:not(.p-rowgroup-header) {
    grid-template-columns: minmax(0, 1fr) Tools.asRem(42px);

    td.name {
      padding: 0 0 0 Tools.asRem(Spacing.$gutter-large);
      overflow: hidden;
    }
  }
}

.currentlyOpen {
  width: Tools.asRem(10px);
  height: Tools.asRem(10px);
  background-color: Colors.$grass;
  border-radius: 2px;
}

.badge {
  padding-right: 0 !important;
  cursor: default;
}

.worksheetError {
	background-color: Colors.$charcoal;
  padding: Tools.asRem(Spacing.$gutter-medium);
}

.menuitem div {
  justify-content: space-between;
}

// .panelButton {
//   background-color: Colors.$panel-background;
// }