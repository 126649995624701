@use 'src/design-system/globals/colors' as Colors;
@use './tools.px-to-rem.scss' as Tool;

@mixin datatable-new-item($border-color: Colors.$ob-brand-orange) {
  tr.newItem,
  tr.p-highlight.p-selectable-row.newItem,
  tr:not(.p-rowgroup-header):not(.p-selected-row).p-highlight.newItem {
    background-color: Colors.$column-header-background;
    font-weight: 700;

    > :first-child {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        border-left: Tool.asRem(4px) solid $border-color;
      }
    }
    
    > :last-child {
      border-right: Tool.asRem(4px) solid $border-color;
    }
  }
}
