@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.grow-to-fill {
    flex-grow: 1;
}

.position {
  &--relative {
    position: relative;
  }
}

.overflow {
    &--hidden {
        overflow: hidden;
    }

    &--y {
        overflow-y: auto;
    }

    &--elipsis {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;

      &--2-lines {
        -webkit-line-clamp: 2;
        max-height: Tools.asRem(36px);
      }

      &--3-lines {
        -webkit-line-clamp: 3;
        max-height: Tools.asRem(54px);
      }
    }
}

.debug {
  border: 1px solid gold;
}


.no {
  &--padding { padding: 0 !important}
  &--background {
      background-color: transparent !important;
  }

	$directions: left, top, right, bottom;

	@each $dir in $directions {
		&--#{$dir}-padding { padding-#{$dir}: unset !important; }
	}

}

.align {
  &--left {
    justify-content: flex-start;
    text-align: left;
  }
  &--right {
    justify-content: flex-end;
    text-align: right;
  }

  &-items {
    &--start {
        align-items: flex-start !important;
				justify-content: center;
    }

    &--center {
      align-items: center;
    }

    &--end {
      align-items: flex-end;
      justify-content: end;
    }
  }
}

.justify-items {
  &--start {
    justify-content: flex-start;
  }

  &--center {
    justify-content: center;
  }

  &--end {
    justify-content: flex-end;
  }
}

.direction {
    &--column {
        flex-direction: column;
    }

    &--row {
      flex-direction: row;
    }
}


.space-between {
    justify-content: space-between;
}

.fit-content {
    &--height {
        height: fit-content;

        @media only screen and (min-width: 960px) {
            padding-bottom: Tools.asRem(Spacing.$gutter-large);
        }

        @media only screen and (max-width: 960px) {
            padding-bottom: Tools.asRem(2 * Spacing.$gutter-large);
        }
    }
}

.hidden {
    display: none !important;
}

.margin {
    &--horizontal {
        &--small {
            margin: 0 Tools.asRem(Spacing.$gutter-small);
        }
    }

    &--right {
        &--small {
            margin: 0 Tools.asRem(Spacing.$gutter-small) 0 0;
        }
    }
}

.direction {
  &--row-reverse { flex-direction: row-reverse; }
}

span[data-np-uid] {
  //	blame Nordpass for this. Seems it now wants to inject it's shitty
  //	logo into any input it can find.
  display: none !important;

}

.text {
  &--capitalize {
    text-transform: capitalize;
  }
  &--uppercase {
    text-transform: uppercase;
  }
}

.content--hidden > * {
  display: none !important;
}
