@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Color;
@use "src/design-system/globals/animations" as Animations;
@use "src/design-system/tools" as Tools;
@use "src/design-system/globals/fonts" as *;

.instance {
	align-items: center;
	gap: Tools.asRem(Spacing.$gutter-small);

	& label {
		@extend %toggle-button-label;
		color: Color.$text-secondary;
	}

	& span:first-child { padding-right: Tools.asRem(Spacing.$gutter-micro)}
	& span:last-child { padding-left: Tools.asRem(Spacing.$gutter-micro)}
}

.reversed {
	flex-direction: row-reverse;
}

.justifyEnd {
	justify-content: flex-end;
}

.disabled {
	opacity: 36%;
}