@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;
@use 'src/design-system/globals/fonts' as Text;

.app__canvas {
    position: relative;
	max-width: 100dvw;
	flex-direction: column;
}

.AppBody {
	overflow-x: hidden;
}

$num-columns: 12;
$min-col-width: (1280px - (64px + 24px)) / $num-columns;

.module__canvas {
    
	flex-direction: column;
	overflow-y: hidden;

	& aside header {
		align-items: center;
		background-color: Colors.$column-header-background;
		border-bottom: 2px solid Colors.$column-header-border;
		gap: Tools.asRem(Spacing.$gutter-small);

		& > hgroup { 
			align-items: baseline;
			gap: Tools.asRem(Spacing.$gutter-small);
;   }

		& h3 { 
			font-family: unset;
			font-weight: 200;
			font-size: Tools.asRem(12px);
			color: Colors.$text-secondary;
		}
	}

	&:has(> header) {

		& > header {
			background-color: Colors.$page-header-background;
			box-shadow: inset 0px -4px 4px rgba(0,0,0, 0.16);
			padding: Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-small);

			@media only screen and (min-width: 960px) {
				padding: Spacing.$gutter-medium  Spacing.$gutter-medium Spacing.$gutter-medium  Spacing.$gutter-large;
				gap: Tools.asRem(Spacing.$gutter-medium);
			}
		}
	}

	& > nav,
	& > main {
		@media only screen and (min-width: 960px) {
				padding-left: Tools.asRem(Spacing.$gutter-medium);
				padding-right: Tools.asRem(Spacing.$gutter-medium);
		}    
	}

	& nav {
		padding-bottom: Tools.asRem(Spacing.$gutter-small);
		
		@media only screen and (min-width: 960px) {
				padding-bottom: Tools.asRem(Spacing.$gutter-medium);
				flex-wrap: wrap;
		}
	}

	& main {

		overflow: hidden;
		position: relative;
		
		$fraction: calc(100 / $num-columns / 10);
		
		gap: Tools.asRem(Spacing.$gutter-small);

		&:has( > *:only-child ) {
			gap: unset;
		}

	@media only screen and (min-width: 960px) {

			
			&[data-cols] { display: grid; }

			@for $i from 1 through $num-columns {
					$remaining: $num-columns - $i;

					&[data-cols="#{$i},#{$remaining}"] {
							content: "#{$i * $fraction},#{$remaining * $fraction}";
							grid-template-columns: #{$i * $fraction}fr minmax(#{$min-col-width * $remaining}, #{$remaining * $fraction}fr);
					}
			}

			// additional definitions for full with…
			&[data-cols="0,#{$num-columns}"] {
				grid-template-columns: 0 1fr;
				gap: 0;
			} 

			&:not([data-cols]) {
					// border: 1px solid red;
					> * { flex-grow: 1;}
			}
	}
	
	@media only screen and (max-width: 960px) {


		&[data-drawer-position="bottom"] {
			& aside {
					position: absolute;
					width: 100vw;
					transition: bottom .565s ease-in;
					bottom: calc(1px - 100%);
					z-index: 1500;
			}

			&.drawer--active aside { 
				bottom: 0; 
				background-color: Colors.$panel-background;
			}
		}

		&[data-drawer-position="alongside-right"] {

				transition: margin-left .265s ease-in;
				margin-left: 0;

				& aside,
				& section {
						min-width: 100dvw;
				} 

				&.drawer--active {
						margin-left: calc(0px - 100dvw);
						gap: 0;
				}

				& aside {
						& nav { 
								padding-bottom: 0;

								& > button {
										width: fit-content;
										padding-left: Tools.asRem(Spacing.$gutter-micro);
										outline: 0;
										box-shadow: unset;
								}
						}
						& > div {
								padding-top: 0;
						}
				}
		}
	}
			

		// & > aside > *:not(header):not(.no-background) ,
		& > section > *:not(header):not(.no-background) {
				background-color: Colors.$panel-background;
				border: 1px solid Colors.$full-black;
				border-radius: 4px 4px 0 0;
				
				&:last-child { border-bottom-width: 0; }

				&:has(+ div[id='configuration']) {
					border-top-right-radius: 0;
				}
		}
		
		& > aside,
		& > section > *.grow-to-fill {
				overflow: hidden;
		} 

		& > aside {
			background-color: Colors.$panel-background-color;
			border: 1px solid Colors.$panel-border-color;
			border-radius: 4px 4px 0 0;
			container: aside-column / inline-size;
			flex-direction: column;
			gap: Tools.asRem(Spacing.$gutter-small);
			position: relative;

			& header {
				// align-items: center;
				min-height: Tools.asRem(Spacing.$table-header-height + 2px);

				& > *:first-child {
					margin-right: auto;
					overflow: hidden;
				}
				
				& h2,
				& h3 {
					@include Text.truncate;
				}

				& h2  {
					font-size: Tools.asRem(14px);
					color: Colors.$text-secondary;
				}

				& h3 {
					font-size: Tools.asRem(11px);
					color: Colors.$text-primary;
				}

				&.no--tabs {
					padding: 0 0 0 Tools.asRem(Spacing.$gutter-small)
				}
			}

			& > *:not(header):not(.p-tabview):not(section):not(div:first-child) {
				padding: 0 Tools.asRem(Spacing.$gutter-small);
				gap: Tools.asRem(Spacing.$gutter-small);
			}
		}


		& > section {
			container: main-body / inline-size;
			flex-direction: column;
			gap: Tools.asRem(Spacing.$gutter-small);
			position: relative;
		}
	}

	& footer {
		display: flex;
		align-items: center;
	}

	& footer:not(.no-background) {
		//	ALL footers will get this property
		background: Colors.$footer-background;
		border-top: 1px solid Colors.$full-black;
		box-shadow: 0 2px 6px rgba(#000, .25) inset;
		gap: Tools.asRem(Spacing.$gutter-small);


		// use padding only if there is any content
		&:has(> *) {
			padding: Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small);
		}
	}

	& > footer {
		//	footers ONLY off the main app canvas container
			flex-direction: column;
			align-items: flex-start;
			padding: 0;
	}

	@media only screen and (max-width: 960px) {
			max-height: 100vh;
			max-height: 100dvh;
			overflow-y: auto;
			max-width: 100vw;
	}

}

div.block {
    display: block;

}

// @container aside-column (max-width: 400px) {

// 	> div {

// 	// 	// padding: 0 Tools.asRem(Spacing.$gutter-small)
// 		border: 1px solid red;
// 	}
// }