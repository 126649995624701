@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.contentHeader {
	top: 0;
	z-index: 200;
	padding: Tools.asRem(Spacing.$gutter-micro) Tools.asRem(Spacing.$gutter-small);
	gap: Tools.asRem(Spacing.$gutter-micro) !important;
	border-top: 1px solid Colors.$panel-border-color;
	border-bottom: 1px solid Colors.$panel-border-color !important;
	color: Colors.$text-secondary;
	font-family: Panton, OpenSans, sans-serif;
	font-weight: 600;
	font-size: Tools.asRem(14px);
	line-height: Tools.asRem(14px);

	> button {
		padding: Tools.asRem(Spacing.$gutter-small);

		> span {
			font-size: Tools.asRem(16px);
			font-weight: 400;
			color: Colors.$ob-brand-orange;
		}
	}

  &NoTopBorder {
    border-top: none;
  }
}

.infoPanel {
  & div {
    flex: 1;
  }

  div[data-pc-section="content"] {
    padding: 0;

    background: none;
    border: none;
  }

  div[data-pc-name="message"] {
    justify-content: flex-start;
  }
}

.gap {
  gap: Tools.asRem(Spacing.$gutter-small);
}

.scroll {
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;

  > div {
    flex: 1;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
}
