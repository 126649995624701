@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

.surveillance-container {
  height: 100%;
  flex-direction: row;

  .surveillance-reports-list {
    flex-direction: column;
    width: Tools.asRem(240px);
    max-width: Tools.asRem(240px);
    border-right: 1px solid Colors.$panel-border-color;
    background-color: Colors.$panel-background;

    header {
      justify-content: space-between;
      height: Tools.asRem(66px);
      border-bottom: 1px solid Colors.$panel-border-color;
      background-color: Colors.$page-header-background;
      box-shadow: inset 0px -4px 4px rgba(0,0,0, 0.16);
      padding: Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-large);

      h1 {
        color: Colors.$text-secondary;
      }

      button {
        color: Colors.$ob-brand-orange;
        height: Tools.asRem(36px);
      }
    }

    &-all-worksheets {
      & > div {
        flex-grow: 1;
        border: 0;

        .p-datatable tr.p-rowgroup-header {
          border-right: 0;
          border-bottom: 1px solid Colors.$datatable-header-border;
        }

        tr:not(.p-rowgroup-header) {
          height: Tools.asRem(36px);
        }
      }
    }
  }

  .surveillance-container-main {
    overflow-x: hidden;

    header.search-bar {
      min-height: Tools.asRem(66px);
      border-bottom: 1px solid Colors.$panel-border-color;
      background-color: Colors.$page-header-background;
      align-items: flex-start;
      gap: Tools.asRem(Spacing.$gutter-small);
      display: flex;

      button.surveillance-toggle-left-panel {
        background-color: Colors.$text-only-button-background;
        color: Colors.$text-secondary;
        min-width: Tools.asRem(42px);
      }

      > button,
      > div {
        height: Tools.asRem(36px);
      }

      &.search-bar-only-toggle {
        background-color: transparent;
        border-bottom: 0;
        box-shadow: unset;

        .surveillance-search-container {
          display: none;
        }
      }
    }

    > main {
      padding: Tools.asRem(Spacing.$gutter-large) Tools.asRem(Spacing.$gutter-large) 0;

      > section {
        background-color: Colors.$panel-background-color;
        border-radius: 4px 4px 0 0;
        gap: 0;
      }

      .p-datatable-footer {
        > footer {
          padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-medium);
        }

        button.surveillance-export,
        button.surveillance-reviewed,
        button.surveillance-escalate {
          color: Colors.$ob-brand-orange;
          background-color: Colors.$ob-brand-black;
          border: 0;
          padding: Tools.asRem(Spacing.$gutter-small);
        }
      }

      footer.surveillance-footer {
        font-family: OpenSans, sans-serif;
        font-size: Tools.asRem(11px);
        font-style: italic;
        font-weight: 400;
        line-height: Tools.asRem(18px);
        justify-content: space-between;
        display: flex;
        background-color: Colors.$ob-brand-black;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-micro);

        .surveillance-active-ws {
          color: Colors.$sky-80;
        }

        .surveillance-last-updated {
          color: Colors.$text-secondary;
        }
      }
    }

    &__nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: Tools.asRem(Spacing.$gutter-large);
      padding-bottom: 0;
      flex-direction: row;
      gap: Tools.asRem(Spacing.$gutter-large);
    }

    &__compressed-view-toggle-label {
      margin-left: auto;
      font-weight: 400;
    }
  }
}
