@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

$indicator-badge-size: Tools.asRem(22px);

.filteringControl {
  [class~='p-badge'] {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;

    height: $indicator-badge-size;
    width: $indicator-badge-size;
    min-width: $indicator-badge-size;

    font-family: OpenSans, sans-serif;
    font-size: Tools.asRem(14px);
    line-height: Tools.asRem(14px);

    color: Colors.$text-primary;
    background-color: Colors.$ob-brand-orange;

    top: Tools.asRem(-10px);
    right: Tools.asRem(-15px);
  }

  [class~='p-overlay-badge'] {
    flex: 1;
    justify-content: flex-end;
  }

  &ContextMenu {
    max-height: Tools.asRem(250px);
    overflow: auto;

    ul > li > div {
      padding: Tools.asRem(Spacing.$gutter-small);
      gap: Tools.asRem(Spacing.$gutter-small);

      align-items: center;

      &.paddingLarge {
        padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-large);
      }
    }
  }
}

.menuItem {
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
}
