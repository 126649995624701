@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;
@use 'src/components/TopMenu/TopMenu' as TopMenu;

.surveillance-escalation-inbox {
  &-container {
    flex-direction: column;
    overflow: hidden;
    padding: 0 Tools.asRem(Spacing.$gutter-medium);
  }

  &__table {
    overflow: hidden;
    background-color: Colors.$panel-background;

    @include Tools.datatable-new-item();

    th:not(.header--double-row) {
      font-weight: 400;
    }

    tr {
      display: grid;

      grid-template-columns: 1fr 1fr Tools.asRem(150px) Tools.asRem(350px) 1fr;

      &[role=row]:has(+ [tabindex="0"]) {
        border-bottom: 1px dashed var(--datatable-row-border) !important;
      }
      
      .not-available__container {
        font-style: italic;
      }
    }

    tr.p-datatable-emptymessage {
      cursor: default;
      color: Colors.$text-primary;

      strong {
        color: Colors.$text-secondary;
        font-family: Panton;
        font-size: Tools.asRem(18px);
      }
    }
  }

  &__dialog {
    z-index: TopMenu.$menu-zIndex !important;
  }
}
