@use 'src/design-system' as *;

.p-calendar {
  &.p-invalid.p-component > .p-inputtext {
    @include invalid-input();
  }
}

.p-datepicker {
  padding: $calendarPadding;
  background: $calendarInlineBg;
  color: $calendarTextColor;
  border: $calendarBorder;
  border-radius: $borderRadius;

  &.p-datepicker-inline {
    flex-direction: column;

    .p-yearpicker,
    .p-monthpicker {
      display: block;
    }
  }

  &:not(.p-datepicker-inline) {
    background: $calendarBg;
    border: $calendarOverlayBorder;
    box-shadow: $inputOverlayShadow;

    .p-datepicker-header {
      background: $calendarHeaderBg;
    }
  }

  .p-datepicker-group {
    flex: 1;
    flex-direction: column;
  }

  .p-datepicker-header {
    padding: $calendarHeaderPadding;
    color: $calendarHeaderTextColor;
    background: $calendarInlineHeaderBg;
    font-weight: $calendarHeaderFontWeight;
    margin: $inputListHeaderMargin;
    border-bottom: $calendarHeaderBorder;
    border-top-right-radius: $borderRadius;
    border-top-left-radius: $borderRadius;

    .p-datepicker-prev,
    .p-datepicker-next {
      @include action-icon();

      height: $calendarHeaderNaviBtnHeight;
      width: $calendarHeaderNaviBtnWidth;
      padding: 0;

      &:enabled:hover {
        background: unset;
        color: $calendarMonthYearHeaderHoverIconsColor;
      }
    }
    .p-datepicker-prev svg {
      margin: $calendarHeaderPrevBtnMargin;
    }
    .p-datepicker-next svg {
      margin: $calendarHeaderNextBtnMargin;
    }

    .p-datepicker-title {
      gap: $calendarHeaderTitleGap;

      .p-datepicker-year,
      .p-datepicker-month,
      .p-datepicker-decade {
        color: $calendarHeaderTextColor;
        transition: $actionIconTransition;
        font-size: $calendarCellDateTodayFontSize;
        font-family: $calendarFontFamily;
        font-weight: $calendarHeaderFontWeight;
        padding: $calendarHeaderCellPadding;

        &:enabled:hover {
          color: $calendarMonthYearHeaderHoverTextColor;
        }
      }
    }
  }

  table {
    font-size: $fontSize;
    margin: $calendarTableMargin;
    padding: $calendarTablePadding;

    tr {
      grid-template-columns: repeat(7, 1fr);
    }

    th,
    td {
      position: relative;
      justify-content: center;
    }

    th {
      padding: $calendarHeaderCellPadding;

      > span {
        margin: $calendarCellDateMargin;
      }
    }

    td {
      padding: $calendarCellDatePadding;

      > span {
        width: 100%;
        margin: $calendarCellDateMargin;
        border-radius: $calendarCellDateBorderRadius;
        transition: $listItemTransition;
        border: $calendarCellDateBorder;
      }

      &.p-datepicker-today {
        font-size: $calendarCellDateTodayFontSize;
        font-weight: $calendarCellDateTodayFontWeight;

        span:not(.p-highlight):not(:hover) {
          color: $calendarCellDateTodayTextColor;
        }
      }

      &:has(> .p-highlight) {
        color: $calendarHighlightTextColor;
        background: $calendarCellHighlightBg;
      }
    }
  }

  .p-datepicker-footer {
    border-top: $calendarHeaderBorder;
  }

  .p-datepicker-buttonbar {
    padding: $calendarButtonBarPadding;
    border-top: $divider;

    .p-button {
      width: auto;
    }
  }

  .p-timepicker {
    border-top: $divider;
    padding: $calendarTimePickerPadding;

    button {
      @include action-icon();

      &:last-child {
        margin-top: 0.2em;
      }
    }

    span {
      font-size: $calendarTimePickerTimeFontSize;
    }

    > div {
      padding: $calendarTimePickerElementPadding;
    }
  }

  &.p-datepicker-timeonly {
    .p-timepicker {
      border-top: 0 none;
    }
  }

  .p-monthpicker {
    margin: $calendarTableMargin;

    .p-monthpicker-month {
      padding: $calendarCellDatePadding;
      transition: $listItemTransition;

      &.p-highlight {
        color: $calendarHighlightTextColor;
        background: $calendarCellHighlightBg;
      }
    }
  }

  .p-yearpicker {
    margin: $calendarTableMargin;

    .p-yearpicker-year {
      padding: $calendarCellDatePadding;
      transition: $listItemTransition;

      &.p-highlight {
        color: $calendarHighlightTextColor;
        background: $calendarCellHighlightBg;
      }
    }
  }

  &.p-datepicker-multiple-month {
    .p-datepicker-group {
      border-left: $divider;
      padding-right: $calendarPadding;
      padding-left: $calendarPadding;
      padding-top: 0;
      padding-bottom: 0;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
        border-left: 0 none;
      }
    }
  }

  // Focus and hover states
  &:not(.p-disabled) {
    // calendar date
    table {
      td {
        &:has(span:not(.p-highlight):not(.p-disabled):hover) {
          color: $calendarHighlightTextColor;
          background: $calendarCellHighlightBg;
        }
        &:has(span.p-highlight:not(.p-disabled):hover) {
          background: $calendarCellHighlightHoverBg;
        }

        &:has(*:focus):after {
          @include focused();
          content: '';
          position: absolute;
          border-radius: $borderRadius;
          top: 0;
          right: 2px;
          bottom: 0;
          left: 2px;
        }
      }
    }

    // month picker
    .p-monthpicker {
      .p-monthpicker-month {
        &:not(.p-disabled) {
          &:hover {
            color: $calendarHighlightTextColor;
            background: $calendarCellHighlightBg;

            &.p-highlight {
              background: $calendarCellHighlightHoverBg;
            }
          }

          &:focus {
            @include focused();
          }
        }
      }
    }

    // year picker
    .p-yearpicker {
      .p-yearpicker-year {
        &:not(.p-disabled) {
          &:hover {
            color: $calendarHighlightTextColor;
            background: $calendarCellHighlightBg;

            &.p-highlight {
              background: $calendarCellHighlightHoverBg;
            }
          }

          &:focus {
            @include focused();
          }
        }
      }
    }
  }
}

@media screen and (max-width: $calendarBreakpoint) {
  .p-datepicker {
    table {
      th,
      td {
        padding: $calendarCellDatePaddingSM;
      }
    }
  }
}
