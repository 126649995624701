@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;

@media only screen and (max-width: 960px) {
  .panelMainContainer[data-drawer-position="alongside-right"]
    > aside:first-of-type {
    min-width: Tools.asRem(300px);
  }
}

.panelMainContainer {
  .rightContainer {
    @include Tools.top-right-element;
    width: unset;

    &.buttons {
      min-width: unset;

      button {
        border-width: 0;
      }
    }
  }
}

.flexCenterParent,
.flexCenterChild {
  flex: 1;
  flex-wrap: wrap;
  align-content: center;
}
.flexCenterParent {
  flex-direction: column;
}
.flexCenterChild {
  flex-direction: row;
}

.topRibbon {
  justify-content: start;
  gap: Tools.asRem(Spacing.$gutter-small);
  border: none;
  border-radius: 0;
  height: auto;
  position: relative;

  & span {
    font-size: Tools.asRem(13px) !important;
    line-height: Tools.asRem(18px);
    font-family: OpenSans, sans-serif;
    font-weight: 700;
  }
}

.tabview {
  & > .p-tabview-title {
    font-weight: 600;
  }

  & ul[class~="p-tabview-nav"] {
    flex-grow: 0;

    > [class~="p-tabview-ink-bar"] {
      display: none;
    }

    & li {
      & a[class~="p-tabview-nav-link"] {
        padding-left: Tools.asRem(Spacing.$gutter-medium);
        padding-right: Tools.asRem(Spacing.$gutter-medium);
      }
    }
  }
}

.scroll {
  flex: 1;
  width: 100%;
  height: 100%;
  position: relative;

  > div {
    flex: 1;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    scroll-behavior: smooth;
  }
}

.itemContainer {
  padding: Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small) 0px;
}
