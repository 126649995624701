@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;

$rowSpacing: Tools.asRem(Spacing.$gutter-small);

.container {
  width: 100%;
  flex-direction: column;
}

.content {
  flex: 1;
  flex-direction: column;
  position: relative;
}

.recordDetailsContainer {
  dl {
    width: 100%;
  }

  & dt {
    align-content: center;
    word-break: break-word;
    font-family: OpenSans, sans-serif;
    font-weight: 700;
    font-size: Tools.asRem(11px);
    line-height: Tools.asRem(15px);
    color: Colors.$text-secondary;
    text-transform: uppercase;
  }
  & dd {
    align-content: center;
    word-break: break-word;
    padding-bottom: Tools.asRem(Spacing.$gutter-small);
  }

  &Multiline {
    flex: 1;
    flex-direction: column;
    padding-bottom: $rowSpacing;

    & dd {
      padding-bottom: 0;
    }
    & div {
      gap: Tools.asRem(Spacing.$gutter-small);
    }
  }

  &Counterpart {
    &[class~='p-accordion'] {
      div[class~='p-accordion-content'] {
        overflow: visible;

        > div {
          padding: Tools.asRem(Spacing.$gutter-small) 0 0;
        }
      }
    }

    div[class~='p-accordion-tab'],
    div[class~='p-accordion-content'] {
      flex-direction: column;
      gap: 0;
    }

    &[class~='p-accordion']
      [class~='p-accordion-tab']
      [class~='p-accordion-header'] {
      border: none;
      padding: 0;
    }
    &[class~='p-accordion']
      [class~='p-accordion-tab']
      [class~='p-accordion-header']
      [class~='p-accordion-header-link'],
    &[class~='p-accordion']
      [class~='p-accordion-tab']
      [class~='p-accordion-header']
      [class~='p-accordion-header-link']:hover {
      border: none;
      background: none;
      outline: none;
      padding: Tools.asRem(Spacing.$gutter-small) 0;
      font-family: OpenSans, sans-serif;
      font-weight: 700;
      font-size: Tools.asRem(11px);
      text-transform: uppercase;

      span {
        line-height: Tools.asRem(18px);
      }
    }
    &[class~='p-accordion']
      [class~='p-accordion-tab']
      [class~='p-accordion-header']
      [class~='p-accordion-header-link']:focus {
      border: Tools.asRem(1px) solid Colors.$text-primary;
      border-radius: Tools.asRem(3px);
    }
  }
}

.dateAndMediaRow {
  flex: 2;
  gap: Tools.asRem(Spacing.$gutter-large);

  &[class~='flex'] > div {
    flex: 2;
  }
}

.lineParticipants {
  &LeftCol {
    width: Tools.asRem(76px);
    align-content: normal;
    color: Colors.$text-secondary;
  }
  &LeftColUser {
    width: Tools.asRem(50px);
    align-content: normal;
    color: Colors.$text-secondary;
  }
  &RightCol {
    align-content: normal;
  }
}
