.surveillanceFooterContainer {
  width: 100%;
  container-type: inline-size;
  container-name: surveillanceFooterContainer;

  > footer {
    flex: 1;
  }
}

@container surveillanceFooterContainer (width < 440px) {
  footer.conditionalColumn:has(> button) {
    flex-direction: column;
    align-items: flex-start;
  }
}
