@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.container  {
  width: 100%;
  flex: 1;
  flex-direction: column;

  padding: 0 Tools.asRem(Spacing.$gutter-small);
  gap: Tools.asRem(Spacing.$gutter-micro);

  & label,
  > label {
    text-transform: uppercase;
    font-weight: 700;
  }

  time {
    padding: Tools.asRem(Spacing.$gutter-small) 0;

    font-size: Tools.asRem(13px);
    font-weight: 400;
  }
}

.border {
  border: 1px solid Colors.$full-black;
  border-radius: Tools.asRem(3px);

  &:has(.mainHeader) {
    border: 3px solid Colors.$slate;
  }
}

.mainHeader {
  padding: Tools.asRem(Spacing.$gutter-small);

  background: Colors.$slate;

  font-size: Tools.asRem(14px);
  font-weight: 800;
  text-transform: uppercase;
}

.content {
  flex: 1;
  flex-direction: column;

  gap: Tools.asRem(Spacing.$gutter-micro);
}

.export {
  @include Tools.dotted-border($edge: bottom);
  padding: Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-small);
  gap: Tools.asRem(Spacing.$gutter-small);

  flex-direction: row;
  align-items: center;

  & label,
  > label {
    color: Colors.$text-secondary;
    text-transform: uppercase;
    font-weight: 700;
  }
}

.versions {
  padding-bottom: Tools.asRem(Spacing.$gutter-small);
  gap: Tools.asRem(Spacing.$gutter-small);

  > div {
    flex: 1;

    &:has([class*='p-dropdown']):has(+ button) {
      min-height: auto;
    }

    [class~="p-dropdown-label"] {
      width: 1px;
    }
  }

  input {
    width: 100%;

    &:disabled {
      opacity: 1;
      color: Colors.$text-primary;
      background: Colors.$ob-brand-black;
    }
  }
}

.surveillanceTranscriptionEdit {
  height: Tools.asRem(34px);

  justify-content: center;

  > span {
    font-size: Tools.asRem(16px);
    font-weight: 400;
    color: Colors.$ob-brand-orange;
  }
}

.message {
  width: 100%;

  padding: 0 Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small);

  & div {
    gap: Tools.asRem(Spacing.$gutter-micro);

    white-space: pre-line;
  }
    
  & span,
  > span {
    font-style: italic;
    color: Colors.$text-secondary;
  }

  > textarea {
    height: 100%;
    width: 100%;
    resize: none;
    padding: 0 Tools.asRem(Spacing.$gutter-small);

    color: Colors.$text-primary;
    background-color: Colors.$ob-brand-black;
    border: 1px solid Colors.$full-black;
  }
}

.highlightsSection {
  > div {
    display: block;
  }

  > * > mark,
  * > mark {
    background-color: Colors.$sky;
    color: Colors.$text-primary;
    font-weight: 700;
  }
}
