@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.dateTimeRangeSelector {
  flex: 1;
  flex-direction: column;
  padding: Tools.asRem(2px) 0;
  gap: Tools.asRem(Spacing.$gutter-micro);

  label {
    color: Colors.$text-secondary;
    font-family: OpenSans, sans-serif;
    font-weight: 700;
    font-size: Tools.asRem(13px);
  }

  > div {
    align-items: center;
    justify-content: center;

    > * {
      flex: 1 0 45%;
    }
    > :nth-child(2) {
      text-align: center;
      flex: 1 0 10%;
    }
  }
}
