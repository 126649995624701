@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/tools" as Tools;

.act-as {
	&--input {
		color: Colors.$text-secondary;
    	background: Colors.$input-background-color;
    	border: 1px solid Colors.$input-border-color;
		border-radius: Tools.asRem(3px);
    	transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
		min-height: Tools.asRem(36px);
		flex-grow: 1;
		padding: Tools.asRem(Spacing.$gutter-small);
		flex-wrap: wrap;
		gap: Tools.asRem(Spacing.$gutter-micro)
	}
}