.datetime-picker {
  &-menu {
    &.p-menu .p-menuitem {
      background: unset;
      color: inherit;
    }
  }

  &-calendar {
    &:has(.p-monthpicker) .p-datepicker-footer,
    &:has(.p-yearpicker) .p-datepicker-footer {
      display: none;
    }
  }
}
