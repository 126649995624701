@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

.workflow-details-filter-option {
  flex-grow: 0;
  background-color: transparent;
  border: none;
  position: relative;

  .p-multiselect-label-container {
    .p-placeholder,
    .p-multiselect-label {
      color: Colors.$text-secondary;
      font-size: Tools.asRem(13px);
    }
  }

  .p-multiselect-panel {
    min-width: Tools.asRem(286px);

    &:has(.p-multiselect-empty-message) {
      padding: Tools.asRem(Spacing.$gutter-large) Tools.asRem(Spacing.$gutter-small);

      .p-multiselect-items {
        padding: 0;
      }
    }

    .workflow-details-filter-option__empty-message-header {
      color: Colors.$text-secondary;
      line-height: Tools.asRem(22px);
    }

    .p-multiselect-footer {
      button {
        color: Colors.$ob-brand-orange;
        background-color: Colors.$ob-brand-black;
      }
    }
  }
}