@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/tools' as Tools;

$contentWidth: 700px;
$boxWidth: 560px;
$avatarSpace: 42px;

.ma-conversation {
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding-top: Tools.asRem(Spacing.$gutter-small);
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  scrollbar-gutter: stable both-edges;

  &-content {
    width: Tools.asRem($contentWidth);
    max-width: Tools.asRem($contentWidth);
    align-items: flex-end;
    flex-direction: column;
    gap: Tools.asRem(Spacing.$gutter-medium);
    padding-bottom: Tools.asRem(Spacing.$gutter-medium);

    .question {
      position: relative;
      border-radius: Tools.asRem(3px);
      background-color: Colors.$slate;
      padding: Tools.asRem(Spacing.$gutter-small);
      max-width: Tools.asRem($boxWidth);
      overflow-x: auto;
    }

    .answer {
      position: relative;
      border-radius: Tools.asRem(3px);
      background: Colors.$overlay-background;
      margin-left: Tools.asRem($avatarSpace);
      padding: Tools.asRem(Spacing.$gutter-small);
      max-width: Tools.asRem($boxWidth);
      flex-direction: column;
      align-self: flex-start;

      &-actions {
        flex-direction: row;
        flex-wrap: wrap;
        gap: Tools.asRem(10px);
        margin-top: Tools.asRem(Spacing.$gutter-small);

        &__button {
          background: Colors.$full-black;
          border-width: Tools.asRem(3px);
          height: Tools.asRem(36px);
        }
      }

      &-loading {
        width: calc(100% - $avatarSpace);
        height: Tools.asRem(50px);
        flex-direction: row;

        @keyframes dots-pulse {
          0% { opacity: 1; }
          100% { opacity: 0; }
        }

        .dots-pulse {
          text-align: left;
          align-items: flex-end;

          span:first-child {
            margin-left: Tools.asRem(5px);
          }

          span:not(:last-child) {
            margin-right: Tools.asRem(2px);
          }

          span {
            animation: dots-pulse 1s infinite;
            animation-delay: 0s;
            line-height: Tools.asRem(5px);
          }

          span:nth-child(2) {
            animation-delay: 100ms;
          }

          span:nth-child(3) {
            animation-delay: 300ms;
          }
        }
      }
    }

    .answer-md {
      max-width: Tools.asRem($contentWidth - $avatarSpace - Spacing.$gutter-large);
      overflow-x: auto;
      word-break: break-word;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }

    .answer, .answer-md {
      display: flex;
      flex-direction: column;

      // Adjust markdown elements
      ul:not(.p-menu-list):not(.p-dropdown-items):not(.p-autocomplete-items):not(.p-tabview-nav):not(.p-multiselect-items):not(.p-tieredmenu-root-list):not(.p-contextmenu-root-list) {
        margin: 0 0 Tools.asRem(Spacing.$gutter-large) Tools.asRem(Spacing.$gutter-large);
      }

      ol:not(.p-menu-list):not(.p-dropdown-items):not(.p-autocomplete-items):not(.p-tabview-nav):not(.p-multiselect-items):not(.p-tieredmenu-root-list):not(.p-contextmenu-root-list) {
        margin: 0 0 Tools.asRem(Spacing.$gutter-large) Tools.asRem(22px); // 2 digit numbered list ol has bigger margin
      }

      p:not(:last-of-type), hr, blockquote, pre, table, h1, h2, h3, h4, h5, h6 {
        margin-bottom: Tools.asRem(Spacing.$gutter-large);
      }

      blockquote {
        font-style: italic;
      }

      pre {
        border: 1px solid Colors.$text-primary;
        padding: Tools.asRem(Spacing.$gutter-large);
      }

      td, th {
        display: table-cell;
      }

      tr {
        display: table-row;
      }
    }

    .p-avatar {
      position: absolute;
      left: Tools.asRem(-$avatarSpace);
      top: 0;
      width: Tools.asRem(34px);
      height: Tools.asRem(34px);
      background: Colors.$overlay-background;
      color: Colors.$ob-brand-orange;

      .p-avatar-icon::before {
        width: Tools.asRem(18px);
        height: Tools.asRem(18px);
      };
    }
  }

  @media only screen and (max-width: 960px) {
    align-items: center;
    flex-direction: column;
    scrollbar-gutter: auto;

    &-content {
      width: 100%;
      max-width: 100%;
      padding: Tools.asRem(Spacing.$gutter-small);

      .question {
        max-width: calc(100vw - 96px); // Custom space from left screen side
      }

      .answer {
        max-width: calc(100vw - 56px - $avatarSpace); // Custom space from right screen side (minus avatar space)
      }
    }
  }
}