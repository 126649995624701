@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;

$num-columns: 12;
$min-col-width: (1280px - (64px + 24px)) / $num-columns;
$fraction: calc(100 / $num-columns / 10);

.supervisor-view-container.module__canvas {
  width: 100%;
  height: 100%;
  flex-grow: 1;

  > main {
    overflow: hidden;
    position: relative;
    gap: Tools.asRem(Spacing.$gutter-small);
    padding: Tools.asRem(Spacing.$gutter-micro);
    margin: 0;
    display: grid;

    @for $i from 1 through $num-columns {
      $remaining: $num-columns - $i;

      &[data-cols="#{$i},#{$remaining}"] {
        content: "#{$i * $fraction},#{$remaining * $fraction}";
        grid-template-columns: #{$i * $fraction}fr minmax(#{$min-col-width * $remaining}, #{$remaining * $fraction}fr);
      }
    }

    &[data-cols="0,#{$num-columns}"] {
      grid-template-columns: 0 1fr;
      gap: 0;
    }

    &:not([data-cols]) {
      > * { flex-grow: 1;}
    }

    .p-datatable-footer {
      max-height: Tools.asRem(50px);

      footer {
        padding: Tools.asRem(Spacing.$gutter-medium) Tools.asRem(Spacing.$gutter-small) Tools.asRem(Spacing.$gutter-small);

        button.surveillance-export,
        button.surveillance-reviewed,
        button.surveillance-escalate {
          color: Colors.$ob-brand-orange;
          background-color: Colors.$ob-brand-black;
          border: 0;
          padding: Tools.asRem(Spacing.$gutter-small);
        }
      }
    }
  }
}
  
.supervisor-view-container {
  gap: Tools.asRem(Spacing.$gutter-small);

  .p-datatable-tbody > tr:not(.p-rowgroup-header) .p-frozen-column {
    background-color: inherit;
  }

  &__note-panel {
    padding: 0 Tools.asRem(Spacing.$gutter-micro);

    .not-available__container {
      font-family: OpenSans, sans-serif;
      font-weight: 400;
      font-size: Tools.asRem(13px);
      line-height: Tools.asRem(18px);
      font-style: italic;
    }

    .p-accordion-header {
      border: 0;

      &-text {
        font-weight: 400;
        font-size: Tools.asRem(14px);
        line-height: Tools.asRem(22px);
      }

      .p-accordion-header-link {
        border: 0;
      }

      .p-accordion-header-link,
      .p-toggleable-content {
        background-color: Colors.$panel-background;
      }
      &:not(.p-disabled).p-highlight {
        .p-accordion-header-link {
          background-color: Colors.$panel-background;
        }
      }
    }
    
    .p-toggleable-content {
      background-color: Colors.$panel-background;
    }

    .p-accordion-tab,
    .p-accordion-content {
      flex-direction: column;
      flex: 1;
    }

    .p-accordion-content {
      padding: Tools.asRem(Spacing.$gutter-small) 0 0;

      & dt {
        font-family: OpenSans, sans-serif;
        font-weight: 700;
        font-size: Tools.asRem(11px);
        line-height: Tools.asRem(15px);
        color: Colors.$text-secondary;

        align-content: center;
        text-transform: uppercase;
      }
      & dd {
        font-family: OpenSans, sans-serif;
        font-weight: 400;
        font-size: Tools.asRem(13px);
        line-height: Tools.asRem(18px);

        align-content: center;
      }
    }
  }
}
