@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/tools' as Tools;
@use 'src/components/TopMenu/TopMenu' as TopMenu;

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent; // Turn off blue/green (teal) onclick highlight on Chrome Mobile simulator
}

.p-component {
  font-size: Tools.asRem($fontSize);
  @media (max-width: 960px) {
    font-size: Tools.asRem(16px);
  }
}

.p-component-overlay {
  // background-color: red;
  // transition-duration: $transitionDuration;
}

.p-disabled,
.p-component:disabled {
  opacity: $disabledOpacity;
}

.p-error {
  color: $errorColor;
}

.p-text-secondary {
  color: $textSecondaryColor;
}

.pi {
  font-size: $primeIconFontSize;
}

.p-link {
  font-size: $fontSize;
  // font-family: $fontFamily;
  border-radius: $borderRadius;

  &:focus {
    @include focused();
  }
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

.p-component-overlay {
  z-index: TopMenu.$menu-zIndex + 1 !important; // Above top panel and also overwrite primereact inline styles

  @keyframes p-component-overlay-enter-animation {
    from {
      background-color: transparent;
    }
    to {
      background-color: rgba(0, 0, 0, Colors.$overlay-background-alpha);
      // background-blend-mode: multiply;
    }
  }

  @keyframes p-component-overlay-leave-animation {
    from {
      background-color: var(--maskbg);
    }
    to {
      background-color: transparent;
    }
  }
}
