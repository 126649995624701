@use "src/design-system/globals/colors" as Colors;
@use "src/design-system/globals/spacing" as Spacing;
@use "src/design-system/tools" as Tools;
@use 'src/components/TopMenu/TopMenu' as TopMenu;

.p-toast {
    opacity: $toastOpacity;
    z-index: TopMenu.$menu-zIndex + 2 !important; // Above top panel and also overwrite primereact inline styles

    &-message {

        &-text {
            flex-direction: column;
        }

        &-text,
        &-content {
            gap: Tools.asRem(Spacing.$gutter-small);
        }

        &-icon {
            width: Tools.asRem(24px);
            height: Tools.asRem(24px);
        }
    }

    &-summary {
        font: 800;
        font-size: Tools.asRem(14px);
    }

    .p-toast-message {

        &-text {
        }

        margin: $toastMargin;
        box-shadow: $toastShadow;
        border-radius: $borderRadius;

        .p-toast-message-content {
            padding: $toastPadding;
            border-width: $toastBorderWidth;

            // .p-toast-message-text {
            // }

            // .p-toast-message

            .p-toast-summary {
                font-weight: $toastTitleFontWeight;
            }

            // .p-toast-detail {
            //     margin: $toastDetailMargin;
            // }
        }

        .p-toast-icon-close {
            width: $toastIconFontSize;
            height: $toastIconFontSize;
            border-radius: $actionIconBorderRadius;
            background: transparent;
            transition: $actionIconTransition;

            &:hover {
                background: rgba(255,255,255,.3);
            }

            &:focus {
                @include focused();
            }
        }

        &.p-toast-message-info {
            background: $infoMessageBg;
            border: $infoMessageBorder;
            border-width: $messageBorderWidth;
            color: $infoMessageTextColor;

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: $infoMessageIconColor;
            }
        }

        &.p-toast-message-success {
            background: $successMessageBg;
            border: $successMessageBorder;
            border-width: $messageBorderWidth;
            color: $successMessageTextColor;

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: $successMessageIconColor;
            }
        }

        &.p-toast-message-warn {
            background: $warningMessageBg;
            border: $warningMessageBorder;
            border-width: $messageBorderWidth;
            color: $warningMessageTextColor;

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: $warningMessageIconColor;
            }
        }

        &.p-toast-message-error {
            background: $errorMessageBg;
            border: $errorMessageBorder;
            border-width: $messageBorderWidth;
            color: $errorMessageTextColor;

            .p-toast-message-icon,
            .p-toast-icon-close {
                color: $errorMessageIconColor;
            }
        }

    }
}
