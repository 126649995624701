@use 'src/design-system/globals/spacing' as Spacing;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/animations' as Animations;
@use 'src/design-system/tools' as Tools;

$menu-zIndex: 1600; // Details in https://dev.azure.com/dingrogu/OBXchange/_wiki/wikis/OBX.wiki/335/Layer-order-(z-index-list)
$header-item-size: Tools.asRem(36px);

.app__canvas.top-menu--unpinned > .AppBody > .app-body__content {
  padding-top: Tools.asRem(20px); // Lower all content 20px so it's not covered with unpinned topmenu
}

.top-menu {

  &__container {
    display: flex;
    width: 100%;
    height: Tools.asRem(Spacing.$menu-height);
    min-height: Tools.asRem(Spacing.$menu-height);
    max-height: Tools.asRem(Spacing.$menu-height);
    align-items: center;
    background-color: Colors.$full-black;
    position: relative;
    flex-direction: row;
    flex-grow: 1;
    top: 0;
    z-index: $menu-zIndex;
    transition: Animations.$animation-panel-position;

    .top-menu--unpinned & {
      position: fixed;
      top: Tools.asRem(Spacing.$menu-hidden-height - Spacing.$menu-height);

      .top-menu__hide-button {
        display: none;
      }

      &:has(.top-menu__content:hover),
      &:has(.top-menu__show-button:active) {
        top: 0;

        .top-menu__hide-button {
          display: flex;
        }
      }

      &:has(.top-menu__hide-button:active) { // Hide button click
        top: Tools.asRem(Spacing.$menu-hidden-height - Spacing.$menu-height);
      }
    }

    .top-menu__show-button,
    .top-menu__hide-button {
      position: absolute;
      bottom: Tools.asRem(-12px);
      left: 50%;
      transform: translateX(-50%);
      width: Tools.asRem(50px);
      height: Tools.asRem(12px);
      background-color: transparent;
      background-image: url('../../../public/img/icons/toggle-top-menu.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center bottom;
      pointer-events: auto;
      cursor: pointer;
      padding: 0;
      border: 0;
      border-radius: 0;
      color: Colors.$ob-brand-orange;
      z-index: $menu-zIndex;

      &.p-button.p-button-icon-only:not(.p-button-sm) span:first-child::before {
        width: Tools.asRem(15px);
        height: Tools.asRem(15px);
        margin-bottom: Tools.asRem(2px);
      }

      .p-ink {
        display: none !important; // disable ripple effect
      }
    }

    .top-menu__hide-button {
      z-index: $menu-zIndex + 1;
    }
  }

  &__content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    gap: Tools.asRem(Spacing.$gutter-large);
    padding: 0 Tools.asRem(Spacing.$gutter-large);

    > .bento, > .profile, > .obx-logo { flex: 0 0 $header-item-size; }
    > .title { flex: 1 1 auto; }
    > .assistant { flex: 0 0 Tools.asRem(246px); }
    > .pin, > .unpin { flex: 0 0 Tools.asRem(24px); }

    > .title {
      gap: Tools.asRem(Spacing.$gutter-micro);
      align-items: center;

      h1 {
        display: flex;
        height: Tools.asRem(32px);
        color: Colors.$text-primary;
        font-size: Tools.asRem(18px);
        font-weight: 400;
        font-family: Panton, OpenSans, sans-serif;
        align-items: center;
      }
    }

    > .assistant {
      height: 100%;
      background-color: Colors.$ob-brand-orange;
      justify-content: center;
      padding: 0;
      margin: 0;
      border: 0;
      border-radius: 0;
      color: Colors.$text-primary;
      font-size: Tools.asRem(16px);
      line-height: Tools.asRem(18px);
      font-weight: 400;
      text-transform: uppercase;
      text-decoration: none;

      i {
        &:before {
          width: Tools.asRem(20px);
          height: Tools.asRem(20px);
        }
      }
    }

    > .pin, > .unpin {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      color: Colors.$ob-brand-orange;
      align-items: center;

      &.p-button.p-button-icon-only:not(.p-button-sm) span:first-child {
        padding: 0;
      }
    }

    > .profile {
      .top-menu__avatar {
        align-items: center;
        justify-content: center;
        width: $header-item-size;

        :first-child {
          height: $header-item-size;
          padding: Tools.asRem(Spacing.$gutter-micro);
          width: $header-item-size;
          background-color: transparent;

          img {
            aspect-ratio: 1 / 1;
            border: Tools.asRem(2px) solid Colors.$slate-40;
          }
        }
      }

      .top-menu__avatar-container {
        position: relative;

        align-items: center;
        justify-content: center;

        $indicator-badge-size: Tools.asRem(16px);
        .p-badge {
          position: absolute;
          left: 57%;
          top: 57%;

          display: flex;
          align-items: center;
          justify-content: center;

          width: $indicator-badge-size;
          height: $indicator-badge-size;
          min-width: $indicator-badge-size;
          z-index: 10;

          border: Tools.asRem(2px) solid Colors.$slate-40;
          border-radius: calc($indicator-badge-size / 2);
        }
      }

      .top-menu__profile-panel-container {
        padding: 0;

        &:before {
          border: solid transparent;
          border-bottom-color: transparent;
        }

        &:after {
          border: solid transparent;
          border-bottom-color: Colors.$overlay-background;
        }
      }
    }

    .obx-logo {
      align-items: center;
      justify-content: center;
      width: $header-item-size;
      height: $header-item-size;

      img {
        aspect-ratio: 1 / 1;
        width: $header-item-size;
        height: $header-item-size;
        border: 0;
        background-color: transparent;
        padding: 0;
      }
    }
  }

  &--overlay.menu-underlay {
    z-index: $menu-zIndex - 1;
  }

  @media only screen and (max-width: 960px) {
    &__container {
      position: relative;
      height: Tools.asRem(Spacing.$menu-mobile-height);
      min-height: Tools.asRem(Spacing.$menu-mobile-height);
      max-height: Tools.asRem(Spacing.$menu-mobile-height);
    }

    &__content {
      gap: Tools.asRem(Spacing.$gutter-small);
      padding: 0 Tools.asRem(Spacing.$gutter-small);

      > .assistant { flex: 0 0 Tools.asRem(50px); }
      > .profile, > .obx-logo { flex: 0 0 $header-item-size; }
      > .pin, > .unpin { display: none; }

      > .title { overflow: hidden; }

      > .assistant {
        font-size: 0;
        overflow: hidden;
        padding-left: Tools.asRem(Spacing.$gutter-small);
      }

      > .profile {
        overflow: hidden;

        .top-menu__avatar {
          align-items: center;
          justify-content: center;
          width: Tools.asRem(52px);
        }

        .top-menu__profile-panel-container {
          // Overwrite inline styles from primereact
          left: auto !important;
          right: 8px !important;
        }
      }

      &:has(.bento-menu__overlay) {
        background-color: Colors.$panel-background;

        > .title,
        > .assistant,
        > .profile,
        > .obx-logo {
          display: none
        }
      }
    }
  }
}