@use 'src/design-system/globals/spacing' as Spacing;

address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  // margin-bottom: Spacing.$gutter-medium;
}

/**
 * Consistent indentation for lists.
 */

ol,
ul {
  &:not(.p-menu-list):not(.p-dropdown-items):not(.p-autocomplete-items):not(
      .p-tabview-nav
    ):not(.p-multiselect-items):not(.p-tieredmenu-root-list):not(
      .p-contextmenu-root-list
    ):not(.p-inputtext) {
    margin-left: Spacing.$gutter-small;
  }
}
