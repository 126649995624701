@use 'src/design-system/tools' as Tools;
@use 'src/design-system/globals/colors' as Colors;
@use 'src/design-system/globals/spacing' as Spacing;

$grid-element-border: 1px dashed Colors.$slate;

.grid-element {
  display: flex;
  flex-direction: column;
  padding: 0 Tools.asRem(Spacing.$gutter-small);

  &:not(:nth-child(2n)) {
    border-right: $grid-element-border;
  }

  &:not(:nth-last-child(-n + 2)) {
    border-bottom: $grid-element-border;
  }

  &__header {
    font-family: OpenSans, sans-serif;
    font-size: Tools.asRem(11px);
    font-weight: 700;
    line-height: Tools.asRem(15px);
    text-align: left;
    color: Colors.$text-secondary;
    text-transform: uppercase;
  }

  &__body {
    font-family: OpenSans, sans-serif;
    font-size: Tools.asRem(13px);
    font-weight: 400;
    line-height: Tools.asRem(18px);
    text-align: left;
    color: Colors.$text-primary;
    padding-bottom: Tools.asRem(Spacing.$gutter-small);

    .cell--unit {
      flex-grow: 1;
      justify-content: space-between;

      :nth-child(2) {
        font-weight: 700;
      }
    }
  }
}
