$menu-height: 60px;
$menu-hidden-height: 8px;
$menu-mobile-height: 48px;


$full-width-table-medium: 1184px;

$empty-page-max-width: 600px;


$table-reflow-break-point: 648px;